import React, { useState, ReactNode, MouseEvent } from 'react';
import AliceCarousel, { EventObject } from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './Carrousel.css'; // Asegúrate de importar el archivo CSS

// const responsive = {
//     0: { items: 1 },
//     568: { items: 2 },
//     1024: { items: 3 },
// };

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: {
        items: 5,
    },
};

interface CarouselProps {
    items: ReactNode[];
}

const createItems = (items: ReactNode[], handleClick: (index: number) => void, handleDragStart: (e: React.DragEvent<HTMLDivElement>) => void) => {
    let deltaX = 0;
    let difference = 0;
    const swipeDelta = 20;

    return items.map((item, i) => (
        <div
            key={i}
            data-value={i + 1}
            className="item aspect-ratio"
            onMouseDown={(e: MouseEvent<HTMLDivElement>) => (deltaX = e.pageX)}
            onMouseUp={(e: MouseEvent<HTMLDivElement>) => (difference = Math.abs(e.pageX - deltaX))}
            onClick={() => difference < swipeDelta && handleClick(i)}
            onDragStart={handleDragStart}
        >
            <div className="content">
                {item}
            </div>
        </div>
    ));
};

const Carousel: React.FC<CarouselProps> = ({ items, ...rest }: any) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleDragStart = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();

    const carouselItems = createItems(items, setActiveIndex, handleDragStart);

    const slidePrev = () => setActiveIndex(activeIndex - 1);
    const slideNext = () => setActiveIndex(activeIndex + 1);
    const syncActiveIndexForSwipeGestures = (e: EventObject) => setActiveIndex(e.item);

    const onSlideChanged = (e: EventObject) => {
        syncActiveIndexForSwipeGestures(e);
        // console.debug(`onSlideChanged => Item's position after changes: ${e.item}. Event:`, e);
    };

    const onUpdated = (e: EventObject) => {
        // console.debug(`onUpdated => Item's position after update: ${e.item}. Event:`, e);
    };

    const props = { ...rest, responsive: rest?.responsive || responsive }
    return (
        <div>
            <AliceCarousel
                mouseTracking
                disableButtonsControls
                items={carouselItems}
                activeIndex={activeIndex}
                onSlideChanged={onSlideChanged}
                onUpdated={onUpdated}
                {...props}
            />
        </div>
    );
};

export default Carousel;
